import React from 'react';
import * as _ from 'lodash';
import { Img, WaveSpans } from './Components';

import littlecreacher_ from './picto/gallery/littlecreacher_.jpeg';
import littlecreacher_2 from './picto/gallery/littlecreacher_2.png';
import Nycto_Cloyne from './picto/gallery/Nycto_Cloyne.jpeg';
import rocktobot from './picto/gallery/rocktobot.png';
import gregory from './picto/gallery/gregory.gif';
import andouilles from './picto/gallery/andouilles.jpeg';
import pyuuromi from './picto/gallery/pyuuromi.jpeg';
import snailpaw from './picto/gallery/snailpaw.jpeg';
import snailpaw2 from './picto/gallery/snailpaw2.jpeg';
import snailpaw3 from './picto/gallery/snailpaw3.jpeg';
import Peach_Herald from './picto/gallery/Peach_Herald.jpeg';
import escaloncito1 from './picto/gallery/escaloncito1.jpeg';
import zevellie from './picto/gallery/zevellie.jpeg';
import coolcandies from './picto/gallery/coolcandies.jpeg';
import medjedjed from './picto/gallery/medjedjed.jpeg';
import self from './picto/gallery/self.jpeg';
import nesterov__artem from './picto/gallery/nesterov__artem.jpeg';
import petday3 from './picto/gallery/petday3.jpeg';
import yobitzy from './picto/gallery/yobitzy.jpeg';
import dugar_quincy from './picto/gallery/dugar_quincy.jpeg';
import seishuuun from './picto/gallery/seishuuun.jpeg';
import plastiboo from './picto/gallery/plastiboo.jpeg';
import ring_411 from './picto/gallery/ring_411.gif';
import Shlimaz from './picto/gallery/Shlimaz.jpeg';
import pseudonymjones from './picto/gallery/pseudonymjones.jpeg';
import FicarraKelly from './picto/gallery/FicarraKelly.jpeg';
import minkymomoattd from './picto/gallery/minkymomoattd.jpeg';
import e_kidd_online from './picto/gallery/e_kidd_online.jpeg';
import PeonyOpossum from './picto/gallery/PeonyOpossum.png';
import dogbongos from './picto/gallery/dogbongos.jpeg';
import AndJamCol from './picto/gallery/AndJamCol.jpg';
import MadDJeann from './picto/gallery/MadDJeann.jpg';
import ichigoyogurtt from './picto/gallery/ichigoyogurtt.jpeg';
import pickles from './picto/gallery/pickles.png';
import pickles2 from './picto/gallery/pickles2.png';
import POKOPIPO from './picto/gallery/POKOPIPO.jpeg';
import CmdrKeepItReal from './picto/gallery/CmdrKeepItReal.jpeg';
import MotElegy from './picto/gallery/MotElegy.jpeg';
import kpguy from './picto/gallery/kreempieguy.jpeg';
import pseudonymjones2 from './picto/gallery/pseudonymjones2.jpeg';
import RosenLynxArt from './picto/gallery/RosenLynxArt.jpeg';
import origommies from './picto/gallery/origommies.jpeg';
import some0therguy1 from './picto/gallery/some0therguy1.jpeg';
import OliviaMeower from './picto/gallery/OliviaMeower.jpeg';
import thedaintyheart from './picto/gallery/thedaintyheart.jpeg';
import bignosebug from './picto/gallery/bignosebug.jpeg';
import Haimadipsa from './picto/gallery/Haimadipsa.png';
import ekidd2 from './picto/gallery/ekidd2.jpeg';
import andouilles2 from './picto/gallery/andouilles2.jpeg';
import itsazuretime from './picto/gallery/itsazuretime.png';

const items = [
  { href: 'https://twitter.com/Shlimaz/status/1348039060099641346', src: Shlimaz, orientation: 'landscape' },
  { href: 'https://twitter.com/littlecreacher_/status/1355238111475691521', src: littlecreacher_, orientation: 'landscape' },
  { href: 'https://twitter.com/Nycto_Cloyne/status/1356077216208220162', src: Nycto_Cloyne, orientation: 'landscaper' },
  { href: 'https://twitter.com/pseudonymjones/status/1348038563707944966', src: pseudonymjones, orientation: 'landscaper' },
  { href: 'https://twitter.com/rocktobot/status/1366933211771772930', src: rocktobot, orientation: 'landscape' },
  { href: 'https://twitter.com/angelfoood/status/1362934670460129284', src: gregory, orientation: 'landscape' },
  { href: 'https://twitter.com/andouilles/status/1366474771198795779', src: andouilles, orientation: 'landscaper' },
  { href: 'https://twitter.com/pyuuromi/status/1363716914250416130', src: pyuuromi, orientation: 'landscape' },
  { href: 'https://twitter.com/snailpaw/status/1358220344939737089', src: snailpaw, orientation: 'landscaper' },
  { href: 'https://twitter.com/snailpaw/status/1365017427243913216', src: snailpaw3, orientation: 'landscaper' },
  { href: 'https://twitter.com/snailpaw/status/1359668948598480899', src: snailpaw2, orientation: 'landscape' },
  { href: 'https://twitter.com/Peach_Herald/status/1363994261624999937', src: Peach_Herald, orientation: 'landscape' },
  { href: 'https://twitter.com/escaloncito1/status/1362610608105918467', src: escaloncito1, orientation: 'landscape' },
  { href: 'https://twitter.com/zevellie/status/1361701963721752576', src: zevellie, orientation: 'landscape' },
  { href: 'https://twitter.com/medjedjed/status/1360007716727627778', src: medjedjed, orientation: 'landscape' },
  { href: 'https://twitter.com/nesterov__artem/status/1359044613789605888', src: nesterov__artem, orientation: 'landscape' },
  { href: 'https://twitter.com/petday3/status/1358609467634376710', src: petday3, orientation: 'landscape' },
  { href: 'https://twitter.com/e_kidd_online/status/1336439433940279296', src: e_kidd_online, orientation: 'landscape' },
  { href: 'https://twitter.com/yobitzy/status/1358234576963862530', src: yobitzy, orientation: 'landscape' },
  { href: 'https://twitter.com/dugar_quincy/status/1354191821585473545', src: dugar_quincy, orientation: 'landscape' },
  { href: 'https://twitter.com/minkymomoattd/status/1339750648871579648', src: minkymomoattd, orientation: 'landscaper' },
  { href: 'https://twitter.com/seishuuun/status/1353052322029576193', src: seishuuun, orientation: 'landscape' },
  { href: 'https://twitter.com/plastiboo/status/1353044819023687681', src: plastiboo, orientation: 'landscape' },
  { href: 'https://twitter.com/dogbongos/status/1367546935704514562', src: dogbongos, orientation: '' },
  { href: 'https://twitter.com/PeonyOpossum/status/1367527551074250762', src: PeonyOpossum, orientation: 'landscape' },
  { href: 'https://twitter.com/MadDJeann/status/1357482469893750786', src: MadDJeann, orientation: 'landscaper' },
  { href: 'https://twitter.com/AndJamCol/status/1357026921494757377', src: AndJamCol, orientation: 'landscape' },
  { href: 'https://twitter.com/ichigoyogurtt/status/1367969320416907264', src: ichigoyogurtt, orientation: 'landscape' },
  { href: 'https://twitter.com/angelfoood/status/1343256003266154496', src: FicarraKelly, orientation: 'landscape' },
  { href: 'https://twitter.com/angelfoood/status/1353041492265345027/', src: ring_411, orientation: 'landscaper' },
  { href: 'https://twitter.com/angelfoood/status/1359340109154443264', src: self, orientation: 'landscape' },
  { href: 'https://twitter.com/coolcandies/status/1360770518018908160', src: coolcandies, orientation: 'landscape' },
  { href: 'https://twitter.com/glaucophyte/status/1351012052580659205', src: pickles, orientation: 'landscape' },
  { href: 'https://twitter.com/glaucophyte/', src: pickles2, orientation: 'landscape' },
  { href: 'https://twitter.com/angelfoood/status/1368242268549881861', src: POKOPIPO, orientation: 'landscape' },
  { href: 'https://twitter.com/CmdrKeepItReal/status/1368745258171916300', src: CmdrKeepItReal, orientation: 'landscape' },
  { href: 'https://twitter.com/MotElegy/status/1369364562517065728', src: MotElegy, orientation: 'landscape' },
  { href: 'https://twitter.com/kreempieguy/status/1369090877709312000', src: kpguy, orientation: 'landscape' },
  { href: 'https://twitter.com/pseudonymjones/status/1369067391850790912', src: pseudonymjones2, orientation: 'landscape' },
  { href: 'https://twitter.com/RosenLynxArt/status/1369077227644719106', src: RosenLynxArt, orientation: 'landscape' },
  { href: 'https://twitter.com/angelfoood/status/1370445855665315840', src: origommies, orientation: 'landscaper' },
  { href: 'https://twitter.com/some0therguy1/status/1370434438329372678', src: some0therguy1, orientation: 'landscape' },
  { href: 'https://twitter.com/littlecreacher_/status/1371883971668111360', src: littlecreacher_2, orientation: 'landscape' },
  { href: 'https://twitter.com/OliviaMeower/status/1374771066795610118', src: OliviaMeower, orientation: 'landscape' },
  { href: 'https://twitter.com/thedaintyheart/status/1375108122294038532', src: thedaintyheart, orientation: 'landscape' },
  { href: 'https://twitter.com/bignosebug/status/1377290641919647744', src: bignosebug, orientation: 'landscape' },
  { href: 'https://twitter.com/Haimadipsa/status/1378799798904573956', src: Haimadipsa, orientation: 'landscape' },
  { href: 'https://twitter.com/e_kidd_online/status/1384746436999352322', src: ekidd2, orientation: 'landscaper' },
  { href: 'https://twitter.com/andouilles/status/1384692855298527232', src: andouilles2, orientation: 'landscape' },
  { href: 'https://twitter.com/itsazuretime/status/1393682633981317122', src: itsazuretime, orientation: 'landscape' },
];

export function Gallery() {
  return <div className="gallery-page pink d-flex flex-column align-items-center pink-world pt-4">
    <h2 className="wave"><WaveSpans text="gallery" /></h2>

    <div className="gallery mx-4">
      {_.shuffle(items).map(({ href, src, orientation }) =>
        <li className={orientation}>
          <a className="gallery-picto" href={href} key={href}>
            <Img src={src} alt={href} />
          </a>
        </li>
      )}
    </div>
    <p className="wave"><WaveSpans text="more to come..." /></p>
  </div>;
}
